import React, { createContext, useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const HierarchyContext = createContext()

const PureHierarchyProvider = ({ value, ...props }) => {
  return <HierarchyContext.Provider value={value} {...props} />
}

const HierarchyProvider = (props) => {
  /*Any changes to this query should also be reflected in the "graphql-queries-node.js" file. */
  const { oxygen } = useStaticQuery(
    graphql`
      fragment HierarchyFields on Oxygen_Hierarchy {
        name
        url
        webpage_id
        is_advanced_search
        advanced_search_sub_header
        advanced_search_part_numbers {
          id
          advanced_search_image
          dynamic_attrs
          competitor_part_numbers
        }
        advanced_search_attributes {
          attribute_display_name
          slug
          priority
        }
        meta_description
        salesforce_code
        custom_header_widget
        custom_link {
          pdf
          sub_text
          link_name
        }
        related_products {
          name
          url
          image
        }
        banners {
          image
        }
        images {
          image
        }
      }
      query {
        oxygen {
          hierarchy(hierarchyName: "Website") {
            ...HierarchyFields
            children {
              ...HierarchyFields
              children {
                ...HierarchyFields
                children {
                  ...HierarchyFields
                  children {
                    ...HierarchyFields
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  let productLevelNode = oxygen.hierarchy.children.find(child => child.name === "Products")
  return <PureHierarchyProvider value={{ hierarchy: productLevelNode }} {...props} />
}

const useHierarchy = () => {
  return useContext(HierarchyContext) || { children: [] }
}

export { HierarchyProvider, PureHierarchyProvider, useHierarchy }